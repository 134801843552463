body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Pacify Angry', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

 .no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Pacify Angry';
  src: url('/public/fonts/Pacify%20Angry.ttf') format('truetype');
}
@font-face {
 font-family: 'Justice Gradient Outline'; 
 src: url('/public/fonts/justicegradout.ttf') format('truetype');
}
@font-face {
 font-family: 'IBM Plex Sans Medium';
 src: url('/public/fonts/ibm-plex-sans-cyrillic-ext-500-normal.woff') format('woff');
}

@font-face {
 font-family: 'Isidora Sans Bold';
 src: url('/public/fonts/isidorasans-bold.otf') format('opentype'); 
}
@font-face {
 font-family: 'Epitaph-Regular';
 src: url('/public/fonts/epitaph-regular-regular.ttf') format('truetype'); 
}
@font-face {
  font-family: 'GeistVF';
  src: url('/public/fonts/GeistVF.ttf') format('truetype');
}

@font-face {
  font-family: 'icomoon';
  
  src: url('/public/fonts/icomoon.ttf') format('truetype');
   
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mangaka:before {
  content: "\e900";
}
.icon-manga:before {
  content: "\e901";
}
.icon-anime:before {
  content: "\e902";
}

.icon-comic:before {
  content: "\e903";
}
